<template>
  <div class="myAddress">

    <div class="addCon">
      <el-button type="primary" @click="AddressExport('add')" :disabled="AddressList.length === 25"
        >新增收货地址</el-button
      ><span class="tips" v-if="AddressList.length">您已创建{{AddressList.length}}个收货地址，最多可创建25个</span>    
    </div>
    <!-- 地址列表 -->
    <div v-loading="isLoading">
    <ul v-if="AddressList.length && !isLoading" >
      <li v-for="(item, index) in AddressList" :key="index" class="addressItem">
                 
        <div class="top">
                     
          <div class="left displayRow">
            <span>{{ item.realName }}</span>
            <el-tag type="warning" class="default" v-show="item.isDefault === 1">默认地址</el-tag>
            <el-tag type="success" class="aligs" v-show="item.alias">{{item.alias}}</el-tag>
          </div>
                     
          <div class="right">
            <i class="el-icon-close" @click="del(item.id)"></i>
          </div>
                   
        </div>
                 
        <div class="infoCon">
                     
          <div class="item">
                          <span class="label">收货人：</span>              
            <span calss="labelVal">{{ item.realName }}</span>            
          </div>
                     
          <div class="item">
                          <span class="label">所在地区：</span>              
            <span calss="labelVal">{{ item.province }}{{ item.city }}{{ item.district }}{{item.town}}</span>            
          </div>
                     
          <div class="item">
                          <span class="label">地址：</span>              
            <span calss="labelVal">{{ item.province }}{{ item.city }}{{ item.district }}{{item.town}}{{item.detail}}</span>            
          </div>
                     
          <div class="item">
                          <span class="label">手机：</span>              
            <span calss="labelVal">{{ item.phone }}</span>            
          </div>
                       
                   
        </div>
                 
        <div class="exportAddress displayRow">
          <div class="setDefault" v-show="item.isDefault === 0" @click="setDefault(item.id)">设为默认</div>
           <div class="edit" @click="AddressExport('edit', item)">编辑</div>
        </div>
       
               
      </li>
           
    </ul>
    <div v-if="AddressList.length === 0 && !isLoading" class="noAddress">
       <el-image
      :src="url"
      fit="fit"></el-image>
      <p class="noAddressText">暂无收货地址</p>
    </div>
    </div>

    <!-- 地址弹框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogShow">
      <el-form :model="form" class="addressForm" label-width="80px">
        <el-form-item label="收货人">
          <el-input
            v-model="form.realName"
            autocomplete="off"
            class="addressIpt"
          ></el-input>
        </el-form-item>

        <el-form-item label="所在地区">
          <v-region type="group" @values="regionChange" v-model="selectedAddress" :town="true"></v-region>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input v-model="form.detail" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input
            type="text"
            v-model="form.phone"
            autocomplete="off"
            class="addressIpt"
          ></el-input>
        </el-form-item>
        <el-form-item label="地址别名">
          <div class="displayRow">
            <el-input
              type="text"
              v-model="form.alias"
              autocomplete="off"
              class="addressIpt"
            ></el-input>
            <div>
              <span class="access">建议填写常用名称</span>
              <el-button
                plain
                size="mini"
                v-for="(item, index) in tips"
                :key="index"
                :type="item.type"
                @click="changeTips(item.val)"
                >{{ item.val }}</el-button
              >
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="saveAddress">保 存 地 址</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAddressList,addAddressList,editAddressList,delAddressList,defaultAddressList} from "@/assets/js/api.js";
export default {
  data() {
    return {
      isLoading:true,
      logo: require("@/assets/image/logo.jpg"),
       url:require('@/assets/image/noData.png'),
      active: 0,
      AddressList: [],
      dialogTitle: "添加收货地址",
      userInfo:'',
      dialogShow: false,
      form: {
        realName: "",
        province: "",
        city: "",
        district: "",
        town: "",
        detail: "",
        phone:'',
        addressKey:'',
        alias:'',
      },
      selectedAddress:{
         province: '',
        city: '',
        area: '',
        town: ''
      },
      tips: [
        { val: "家里", type: "success" },
        { val: "父母家", type: "danger" },
        { val: "公司", type: "warning" },
      ],
    };
  },
  components: {},
  methods: {
    // province 省  city 市 district 区 town 街道  detail 详细地址 realName 收货人 phone 手机号码  isDefault 是否默认
    // 保存收货地址
    saveAddress() {
      if (!this.form.realName) {
        this.$message({
          message: "请您填写收货人姓名",
          type: "warning",
        });
        return;
      } else if (!this.form.province) {
        this.$message({
          message: "请您选择所在省",
          type: "warning",
        });
        return;
      } else if (!this.form.city) {
        this.$message({
          message: "请您选择所在市",
          type: "warning",
        });
        return;
      } 
      else if (!this.form.district) {
        this.$message({
          message: "请您选择所在区/县",
          type: "warning",
        });
        return;
      } 
       else if (!this.form.town) {
        this.$message({
          message: "请您选择所在乡/镇/街道",
          type: "warning",
        });
        return;
      } 
      else if (!this.form.detail) {
        this.$message({
          message: "请您填写详细地址",
          type: "warning",
        });
        return;
      } else if (!this.form.phone) {
        this.$message({
          message: "请您填写手机号码",
          type: "warning",
        });
        return;
      } else {
        this.addAddress();
      }
    },
    // 新增地址
    addAddress(){
      if(this.dialogTitle === "编辑收货地址"){
        editAddressList(this.form,this.userInfo.token).then(res=>{
          if(res.data.code === 200){
              this.$message({
          message: '修改地址成功',
          type: 'success'
        });
          this.dialogShow = false;
            this.getAddress();
          }
      })
      }else{
              addAddressList(this.form,this.userInfo.token).then(res=>{
          if(res.data.code === 200){
              this.$message({
          message: '新增地址成功',
          type: 'success'
        });
          this.dialogShow = false;
            this.getAddress();
          }
      })
      }

    },

    //设为默认
    setDefault(id){
      defaultAddressList({id:id},this.userInfo.token).then(res =>{
        if(res.data.code === 200){
          this.getAddress();
        }
      })
    },

    // 改变常用地址别称
    changeTips(val) {
      this.form.alias = val;
    },
    regionChange(data) {
      if(data.province && data.city && data.area && data.town){
          this.form.province = data.province.value;
      this.form.city = data.city.value;
      this.form.district = data.area.value;
      this.form.town = data.town.value;
      this.form.addressKey = `${data.province.key},${data.city.key},${data.area.key},${data.town.key}`;
      }
    },
    //   删除收货地址
    del(id) {
      this.$confirm("确定删除此收货地址?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delAddressList({id:id},this.userInfo.token).then(res =>{
            if(res.data.code === 200){
              this.$message({
                message: '删除地址成功',
                type: 'success'
              });
               this.getAddress();
            }
          })
        })
        .catch(() => {});
    },
    AddressExport(type, item) {
      if (type === "add") {
        this.dialogTitle = "添加收货地址";
      } else {
        this.dialogTitle = "编辑收货地址";
        this.form.id = item.id;
        this.form.realName = item.realName;
        this.form.detail = item.detail;
        this.form.phone = item.phone;
        this.form.alias = item.alias;
        let arr = item.addressKey.split(',');
        this.selectedAddress.province = arr[0];
        this.selectedAddress.city = arr[1];
        this.selectedAddress.area = arr[2];
        this.selectedAddress.town = arr[3];
      }
        this.dialogShow = true;
    },
    getAddress(){
      this.isLoading = true;
          getAddressList(this.userInfo.token).then((res) => {
      if (res.data.code === 200) {
        this.AddressList = res.data.result;
      }
    }).finally(() =>{
      this.isLoading = false;
    })
    },
  },
  created() {
     this.userInfo  = localStorage.getItem('UserInfo') ? JSON.parse(localStorage.getItem('UserInfo')):{};
     this.getAddress();
  },
};
</script>

<style scoped>
.myAddress {
  width: 1200px;
  min-height: 450px;
  margin-left: auto;
  margin-right: auto;
}
.addCon {
  margin-bottom: 20px;
}
.tips {
  margin-left: 20px;
  font-size: 12px;
  color: #aaa;
}

.addressItem {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
  position: relative;
  color: #666;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.infoCon {
  line-height: 23px;
  font-size: 12px;
  width: 500px;
}
.label {
  width: 70px;
  color: #999;
  text-align: right;
  display: inline-block;
}
.exportAddress{
   position: absolute;
  bottom: 10px;
  right: 20px;
  font-size: 13px;
  color: #005ea7;
}
.setDefault{
  margin-right: 20px;
}
.edit,.setDefault{
 cursor: pointer;
}

.el-icon-close {
  font-size: 25px;
  color: #aaa;
}
.access {
  font-size: 12px;
  color: #aaa;
  margin: 0 10px;
}
.noAddress{
  width:100%;
  text-align: center;

}
.icon-zanwushuju{
  font-size: 150px;
}
.noAddressText{
  padding-top:20px;
  letter-spacing: 1px;
  color:#aaa;
}
</style>